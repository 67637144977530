import { Page } from '@/utils';
import { UserVerificationStatus } from '@/interfaces';

export default defineNuxtRouteMiddleware(async (to) => {
  const localePath = useLocalePath()

  const authStore = useAuthStore()
  const userStore = useUserStore()
  const globalStore = useGlobalStore()

  if (process.client) {
    if (!authStore.token) return localePath({ name: Page.SIGNIN })

    const user = await userStore.initUser()
    if (!user) location.reload()

    if (!userStore.getIsSocket) userStore.connectSocket()

    try {
      await globalStore.loadCountryList()
    } catch {
      return localePath({ name: Page.SIGNIN })
    }
  } else {
    const token = useCookie('authorization')
    if (!token.value) return localePath({ name: Page.SIGNIN })

    authStore.setToken(token.value)

    try {
      await userStore.initUser()
    } catch {
      return localePath({ name: Page.SIGNIN })
    }
  }

  if (
    ![Page.PROFILE, Page.PROFILE_VERIFICATION].includes(to.name.split('___')[0]) &&
    userStore.getIsInit &&
    userStore.getVerificationStatus !== UserVerificationStatus.APPROVED
  ) {
    return localePath({ name: Page.PROFILE_VERIFICATION })
  }
})
